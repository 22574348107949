import { InputTemplateFile } from 'src/domain/entity/InputTemplateFile';
import { ModelType } from 'src/domain/entity/ModelType';
import { River } from 'src/domain/entity/River';
import { SanityCheckOutput } from 'src/domain/entity/SanityCheckOutput';
import { SanityCheckProgress } from 'src/domain/entity/SanityCheckProgress';
import { Scenario } from 'src/domain/entity/Scenario';
import { WarningLogRecord } from 'src/domain/entity/WarningLogRecord';
import { GetMergeFlowConfigurationHistoricalOutput } from 'src/domain/service/RiverSystemService/dto';
import ScenarioService from 'src/domain/service/ScenarioService';
import {
  GetByScenarioStateInput,
  GetSharepointInputFileLastModifiedDateOutput,
  SubmitSettingsInput,
} from 'src/domain/service/ScenarioService/dto';
import HttpAdapter from 'src/infra/http/HttpAdapter';

export default class ScenarioRestService implements ScenarioService {
  constructor(readonly httpAdapter: HttpAdapter) {}

  public async getAll(riverSystemId: number): Promise<Array<Scenario>> {
    const data: any = await this.httpAdapter.get('api/services/app/Scenario/GetAll', {
      riverSystemId,
    });
    return data.result;
  }

  async create(
    name: string,
    type: ModelType,
    riverSystem: River,
    description: string,
  ): Promise<Scenario> {
    const riverSystemId = riverSystem.id;
    const body = { name, type, riverSystemId, description };
    const data = await this.httpAdapter.post<{ result: Scenario }>(
      'api/services/app/Scenario/Create',
      body,
    );

    return data.result;
  }

  async reset(scenarioId: number): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/Reset', { Id: scenarioId });
  }

  async resetToRunnable(scenarioId: number): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/ResetToRunnable', {
      Id: scenarioId,
    });
  }

  async duplicate(
    originalId: number,
    newName: string,
    description: string,
    riverSystemId: number,
  ): Promise<Scenario> {
    const body = { originalId, newName, description, riverSystemId };
    const data = await this.httpAdapter.post<{ result: Scenario }>(
      'api/services/app/Scenario/Duplicate',
      body,
    );

    return data.result;
  }

  async delete(scenarioId: number): Promise<void> {
    await this.httpAdapter.delete<{ result: Scenario }>('api/services/app/Scenario/Delete', {
      Id: scenarioId,
    });
  }

  async softDelete(scenarioId: number): Promise<void> {
    await this.httpAdapter.delete<{ result: Scenario }>('api/services/app/Scenario/Delete', {
      Id: scenarioId,
      SoftDelete: true,
    });
  }

  async cancelRun(scenarioId: number): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/Cancel', { Id: scenarioId });
  }

  async cancelSanityCheck(scenarioId: number): Promise<Scenario> {
    const data = await this.httpAdapter.post<{ result: Scenario }>(
      'api/services/app/Scenario/CancelSanityCheck',
      {
        Id: scenarioId,
      },
    );

    return data.result;
  }

  async setCancelSanityCheck(scenarioId: number): Promise<Scenario> {
    const data = await this.httpAdapter.post<{ result: Scenario }>(
      'api/services/app/Scenario/SetCancelSanityCheck',
      {
        Id: scenarioId,
      },
    );

    return data.result;
  }

  async setInitiatingCancelSanityCheck(scenarioId: number): Promise<Scenario> {
    const data = await this.httpAdapter.post<{ result: Scenario }>(
      'api/services/app/Scenario/SetInitiatingCancelSanityCheck',
      {
        Id: scenarioId,
      },
    );

    return data.result;
  }

  async editScenarioDescription(id: number, description: string): Promise<void> {
    await this.httpAdapter.put<void>('api/services/app/Scenario/ChangeDescription', {
      Id: id,
      Description: description,
    });
  }

  async editScenarioName(id: number, name: string): Promise<void> {
    await this.httpAdapter.put<void>('api/services/app/Scenario/ChangeName', {
      Id: id,
      NewName: name,
    });
  }

  async getByName(name: string): Promise<Scenario> {
    const data = await this.httpAdapter.get<{ result: Scenario }>(
      'api/services/app/Scenario/GetByName',
      {
        name,
      },
    );

    return data.result;
  }

  async submitSettings(input: SubmitSettingsInput): Promise<void> {
    await this.httpAdapter.put<void>('api/services/app/Scenario/SubmitSettings', input);
  }

  async get(id: number): Promise<Scenario> {
    const data = await this.httpAdapter.get<{ result: Scenario }>('api/services/app/Scenario/Get', {
      scenarioId: id,
    });

    return data.result;
  }

  async getSanityCheckProgress(scenarioId: number): Promise<SanityCheckProgress> {
    const data: any = await this.httpAdapter.get(
      'api/services/app/Scenario/GetSanityCheckProgress',
      {
        Id: scenarioId,
      },
    );

    return data.result;
  }

  async getProgressFromActiveRuns(scenarioIds: Array<number>): Promise<Array<any>> {
    const data: any = await this.httpAdapter.get(
      'api/services/app/Scenario/GetProgressFromActiveRuns',
      {
        scenarioIds,
      },
    );

    return data.result;
  }

  async getInputTemplateFileUrl(scenarioId: number): Promise<InputTemplateFile> {
    const data: any = await this.httpAdapter.get(
      'api/services/app/Scenario/GetSharepointInputFiles',
      {
        Id: scenarioId,
      },
    );

    return {
      accessUrl: data.result.accessUrl,
    };
  }

  async getInputTemplateFileUploadUrl(scenarioId: number): Promise<string> {
    const data: any = await this.httpAdapter.put(
      'api/services/app/Scenario/GenerateInputUploadUrl',
      {
        Id: scenarioId,
      },
    );

    const file = data.result ?? {};
    if (!file?.accessUrl) {
      throw new Error('The upload URL is not available.');
    }

    return file.accessUrl;
  }

  async runSanityCheck(scenarioId: number): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/RunSanityCheckWithSharepoint', {
      Id: scenarioId,
    });
  }

  async runScenario(scenarioId: number): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/RunScenario', {
      Id: scenarioId,
    });
  }

  async getFiles(scenarioId: number): Promise<SanityCheckOutput> {
    const data: any = await this.httpAdapter.get('api/services/app/Scenario/GetFiles', {
      Id: scenarioId,
    });

    const inputXlsxFiles = data.result.files['InputFiles/xlsxFile'];
    const plotFiles = data.result.files['SanityCheckFiles/PlotHtml(SanityCheckPassed)'];
    const warningLogFiles = data.result.files['SanityCheckFiles/WarningLog(SanityCheckPassed)'];
    const errorLocatorFiles = data.result.files['SanityCheckFiles/ErrorLocator(SanityCheckFailed)'];

    const inputXlsxUrl = inputXlsxFiles ? inputXlsxFiles[0].accessUrl : '';
    const plotUrl = plotFiles ? plotFiles[0].accessUrl : '';
    const successCsvUrl = warningLogFiles ? warningLogFiles[0].accessUrl : '';
    const failCsvUrl = errorLocatorFiles ? errorLocatorFiles[0].accessUrl : '';

    if (plotUrl && successCsvUrl) {
      return {
        csvUrl: successCsvUrl,
        inputXlsxUrl: inputXlsxUrl ?? '',
        plots: plotUrl,
      };
    }

    return {
      csvUrl: failCsvUrl ?? '',
      inputXlsxUrl: inputXlsxUrl ?? '',
      plots: '',
    };
  }

  async scheduleRunScenario(scenarioId: number, scheduleStartTime: string | null): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/UpdateScheduledStartTime', {
      Id: scenarioId,
      ScheduleStartTime: scheduleStartTime,
    });
  }

  async updateEmailNotification(
    scenarioId: number,
    email: string,
    isToSendEmailNotification: boolean,
  ): Promise<void> {
    await this.httpAdapter.post<void>('api/services/app/Scenario/UpdateEmailNotification', {
      Id: scenarioId,
      email,
      isToSendEmailNotification,
    });
  }

  async getByScenarioState(params: GetByScenarioStateInput): Promise<Array<Scenario>> {
    const data = await this.httpAdapter.get<{ result: Array<Scenario> }>(
      'api/services/app/Scenario/GetAll',
      {
        ...params,
        states: params.states.length ? params.states : [params.states],
      },
    );

    return data.result;
  }

  async getRunModelOutputUrl(scenarioId: number): Promise<string> {
    const data: any = await this.httpAdapter.get('api/services/app/Scenario/GetFiles', {
      Id: scenarioId,
    });

    const zipFile = data.result?.files?.OutputFiles;
    const zipUrl = zipFile ? zipFile[0].accessUrl : '';
    if (!zipUrl) throw new Error(`The zipfile doesn't exist`);

    return zipUrl;
  }

  async getWarningLogContent(scenarioId: number): Promise<WarningLogRecord[]> {
    const data: any = await this.httpAdapter.get('api/services/app/Scenario/GetWarningLogContent', {
      Id: scenarioId,
    });

    return data.result;
  }

  async GetScenarioMergeFlowConfigurationHistoricalOutput(
    id: number,
  ): Promise<GetMergeFlowConfigurationHistoricalOutput> {
    const data: any = await this.httpAdapter.get(
      'api/services/app/Scenario/GetScenarioMergeFlowConfigurationHistoricalOutput',
      {
        scenarioId: id,
      },
    );

    return data.result;
  }

  async getSharepointInputFileLastModifiedDate(
    scenarioId: number,
  ): Promise<GetSharepointInputFileLastModifiedDateOutput> {
    const data: any = await this.httpAdapter.get(
      'api/services/app/Scenario/GetSharepointInputFileLastModifiedDate',
      {
        Id: scenarioId,
      },
    );

    return data.result;
  }
}

import { makeAutoObservable } from 'mobx';
import { ScenarioState } from 'src/domain/entity/ScenarioState';
import ScenarioService from 'src/domain/service/ScenarioService';
import RootStore from 'src/stores/rootStore';
import Utils from 'src/utils/utils';

interface Draft {
  id: number;
  scenarioName: string;
  description: string;
  status: ScenarioState;
}

type CurrentStep = 'FETCHING' | 'DONE' | 'REFETCHING';

export default class DraftStore {
  _: RootStore;

  currentStep: CurrentStep = 'FETCHING';

  errorDescription = '';

  drafts: Array<Draft> = [];

  constructor(rootStore: RootStore, readonly scenarioService: ScenarioService) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  async fetchDrafts(): Promise<void> {
    this.setCurrentStep('FETCHING');
    await this.getDrafts();
  }

  async refetchDrafts(): Promise<void> {
    this.setCurrentStep('REFETCHING');
    await this.getDrafts();
  }

  private async getDrafts(): Promise<void> {
    try {
      const drafts = await this.scenarioService.getByScenarioState({
        states: [
          'Created',
          'DatabricksGenerateFileJobFailed',
          'DatabricksGenerateFileJobSuccessful',
          'SanityCheckStarted',
          'SanityCheckFailed',
          'SanityCheckDatabricksFailed',
          'SanityCheckSuccessful',
          'CancellingSanityCheck',
          'InitiatingCancelSanityCheck',
          'ScenarioResetOrDuplicated',
        ],
      });
      const mappedDrafts = drafts.map((draft) => ({
        id: draft.id,
        scenarioName: draft.name,
        description: draft.description,
        status: draft.state,
      }));
      this.setDrafts(mappedDrafts);
    } catch (err) {
      this.errorDescription = Utils.getErrorMessage(err);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  public setDrafts(drafts: Array<Draft>): void {
    this.drafts = drafts;
  }

  public setCurrentStep(currentStep: CurrentStep): void {
    this.currentStep = currentStep;
  }

  get isRefetching(): boolean {
    return this.currentStep === 'REFETCHING';
  }

  get isLoading(): boolean {
    return this.currentStep === 'FETCHING';
  }
}

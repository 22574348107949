import { routers } from 'src/components/Router/router.config';
import { L } from 'src/utils/abpUtility';
import * as abpTypings from 'src/assets/js/abp';

declare let abp: any;

class Utils {
  static loadScript(url: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.body.appendChild(script);
  }

  static extend(...args: any[]) {
    let options;
    let name;
    let src;
    let srcType;
    let copy;
    let copyIsArray;
    let clone;
    let target = args[0] || {};
    let i = 1;
    const { length } = args;
    let deep = false;
    if (typeof target === 'boolean') {
      deep = target;
      target = args[i] || {};
      i += 1;
    }
    if (typeof target !== 'object' && typeof target !== 'function') {
      target = {};
    }
    if (i === length) {
      target = this;
      i -= 1;
    }
    for (; i < length; i += 1) {
      options = args[i];
      if (options !== null) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (name in options) {
          src = target[name];
          copy = options[name];
          if (target !== copy) {
            srcType = Array.isArray(src) ? 'array' : typeof src;
            copyIsArray = Array.isArray(copy);
            if (deep && copy && (copyIsArray || typeof copy === 'object')) {
              if (copyIsArray) {
                copyIsArray = false;
                clone = src && srcType === 'array' ? src : [];
              } else {
                clone = src && srcType === 'object' ? src : {};
              }
              target[name] = this.extend(deep, clone, copy);
            } else if (copy !== undefined) {
              target[name] = copy;
            }
          }
        }
      }
    }

    return target;
  }

  static getPageTitle = (pathname: string): string => {
    const route = routers.filter((r) => r.path === pathname);
    const localizedAppName = L('AppName');
    if (!route || route.length === 0) {
      return localizedAppName;
    }

    return `${L(route[0].title)} | ${localizedAppName}`;
  };

  static getRoute = (path: string): any => {
    return routers.filter((route) => route.path === path)[0];
  };

  static setLocalization(): void {
    if (!abp.utils.getCookieValue('Abp.Localization.CultureName')) {
      const { language } = navigator;
      abp.utils.setCookieValue(
        'Abp.Localization.CultureName',
        language,
        new Date(new Date().getTime() + 5 * 365 * 86400000),
        abp.appPath,
      );
    }
  }

  static capitalLetter(str: string): string {
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }

  static generateCn(prefix: string): (str: string) => string {
    return (str: string) => `${prefix}${str}`;
  }

  /** Make sure what you're copying is JS compatible */
  static cloneObject(obj: Record<string, any>): Record<string, any> {
    return JSON.parse(JSON.stringify(obj));
  }

  static isCypressRunning(): boolean {
    return localStorage.getItem('cypressOn') === 'true';
  }

  static getErrorMessage(err: unknown): string {
    if (err instanceof Error) {
      try {
        const errorResponse = (err as any).response;
        return (
          errorResponse?.data?.error?.validationErrors[0]?.message ||
          errorResponse?.data?.error?.details ||
          err.message
        );
      } catch {
        return err.message;
      }
    }
    return 'Unknown Error';
  }

  static getCurrentClockProvider(currentProviderName: string): abpTypings.timing.IClockProvider {
    if (currentProviderName === 'unspecifiedClockProvider') {
      return abp.timing.unspecifiedClockProvider;
    }

    if (currentProviderName === 'utcClockProvider') {
      return abp.timing.utcClockProvider;
    }

    return abp.timing.localClockProvider;
  }
}

export default Utils;

import { EntityChange } from 'src/domain/entity/EntityChange';
import { Country } from 'src/domain/entity/Country';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import {
  GetMergeFlowConfigurationOutput,
  RiverSystemCheckRiverChangesOutput,
  RiverSystemCreateInput,
  RiverSystemHistoryLogInput,
  RiverSystemHistoryLogOutput,
  RiverSystemUpdateInput,
} from 'src/domain/service/RiverSystemService/dto';
import { toEntityChanges } from 'src/infra/gateway/api/mappers';
import HttpAdapter from 'src/infra/http/HttpAdapter';
import { z } from 'zod';
import { Region } from 'src/domain/entity/Region';

export default class RiverSystemRestService implements RiverSystemService {
  constructor(readonly httpAdapter: HttpAdapter) {}

  async fetchRiverSystemsWithActiveScenarios(): Promise<Array<River>> {
    const data = await this.httpAdapter.get<{
      result: Array<River>;
    }>('api/services/app/RiverSystem/GetAllRiverSystemsWithExistingScenario');

    return data.result;
  }

  async getCountriesGrouppedByRegion(): Promise<Array<Country>> {
    const data = await this.httpAdapter.get<{
      result: Array<Country>;
    }>('api/services/app/Country/GetCountryList');

    const countries = data.result;
    return countries;
  }

  async getAllCountries(): Promise<Array<Country>> {
    const data = await this.httpAdapter.get<{
      result: Array<Country>;
    }>('api/services/app/Country/GetSupportedCountryList');

    const countries = data.result;
    return countries;
  }

  async getByCountryAndRegion(country: string, region: string): Promise<Array<River>> {
    const data: any = await this.httpAdapter.get('api/services/app/RiverSystem/GetAllFiltered', {
      countryIso: country,
      region,
    });

    return data.result;
  }

  async getByRegionId(regionId: number, validRiverSystemOnly: boolean): Promise<Array<River>> {
    const data: any = await this.httpAdapter.get('api/services/app/RiverSystem/GetByRegionId', {
      regionId,
      validRiverSystemOnly,
    });

    return data.result;
  }

  async get(id: number): Promise<River> {
    const data = await this.httpAdapter.get<{
      result: River;
    }>('api/services/app/RiverSystem/Get', { id });

    return data.result;
  }

  async getAll(): Promise<Array<River>> {
    const data = await this.httpAdapter.get<{
      result: { items: Array<River> };
    }>('api/services/app/RiverSystem/GetAll', { MaxResultCount: 100, Sorting: 'name' });

    return data.result.items;
  }

  async getByName(name: string): Promise<River> {
    const data = await this.httpAdapter.get<{
      result: River;
    }>('api/services/app/RiverSystem/GetByName', { name });

    return data.result;
  }

  async create(input: RiverSystemCreateInput): Promise<void> {
    await this.httpAdapter.post('api/services/app/RiverSystem/Create', input);
  }

  async update(input: RiverSystemUpdateInput): Promise<void> {
    await this.httpAdapter.put('api/services/app/RiverSystem/Update', input);
  }

  async getHistoryLog(input: RiverSystemHistoryLogInput): Promise<RiverSystemHistoryLogOutput> {
    const data = await this.httpAdapter.get<z.infer<typeof schema>>(
      'api/services/app/RiverSystem/GetHistoryLog',
      input,
    );

    const schema = z.object({
      result: z.object({
        items: z.array(
          z.object({
            id: z.number(),
            riverSystemName: z.string(),
            username: z.string(),
            creationTime: z.string(),
          }),
        ),
        total: z.number(),
      }),
    });

    schema.parse(data);
    return data.result;
  }

  async getHistoryLogDetail(id: number): Promise<EntityChange[]> {
    const data = await this.httpAdapter.get<z.infer<typeof schema>>(
      'api/services/app/RiverSystem/GetHistoryLogDetail',
      { id },
    );
    const schema = z.object({
      result: z.array(
        z.object({
          id: z.number(),
          entityName: z.string(),
          entityValue: z.string(),
          changeType: z.string(),
          propertyChanges: z.array(
            z.object({
              id: z.number(),
              newValue: z.string(),
              originalValue: z.string(),
              propertyName: z.string(),
            }),
          ),
        }),
      ),
    });

    schema.parse(data);
    const newItems = toEntityChanges(data.result);
    return newItems;
  }

  async checkIfRiverHasChanged(id: number): Promise<RiverSystemCheckRiverChangesOutput> {
    const data = await this.httpAdapter.get<z.infer<typeof schema>>(
      'api/services/app/RiverSystem/CheckIfRiverHasChanged',
      {
        id,
      },
    );
    const schema = z.object({
      result: z.object({
        hasRiverChanged: z.boolean(),
        changeTime: z.string(),
        items: z.array(
          z.object({
            id: z.number(),
            entityName: z.string(),
            entityValue: z.string(),
            changeType: z.string(),
            propertyChanges: z.array(
              z.object({
                id: z.number(),
                newValue: z.string(),
                originalValue: z.string(),
                propertyName: z.string(),
              }),
            ),
          }),
        ),
      }),
    });

    schema.parse(data);
    const newItems = toEntityChanges(data.result.items);
    return {
      hasRiverChanged: data.result.hasRiverChanged,
      changeTime: data.result.changeTime,
      items: newItems,
    };
  }

  async markRiverChangeAsRead(riverSystemId: number): Promise<void> {
    await this.httpAdapter.put('api/services/app/RiverSystem/MarkRiverChangeAsRead', {
      id: riverSystemId,
    });
  }

  async getMergeFlowConfiguration(id: number): Promise<GetMergeFlowConfigurationOutput> {
    const res: any = await this.httpAdapter.get(
      'api/services/app/RiverSystem/GetMergeFlowConfiguration',
      {
        id,
      },
    );
    return res.result;
  }

  // #region Countries
  async deleteCountry(country: Country): Promise<void> {
    const res: any = await this.httpAdapter.delete('api/services/app/Country/Delete', {
      id: country.isoCode,
    });
    return res.result;
  }

  async addCountry(country: Country): Promise<Country> {
    const res: { result: Country } = await this.httpAdapter.post(
      'api/services/app/Country/Create',
      {
        id: country.isoCode,
        name: country.name,
      },
    );
    return res.result;
  }

  async updateCountry(country: Country): Promise<Country> {
    const res: { result: Country } = await this.httpAdapter.put('api/services/app/Country/Update', {
      id: country.isoCode,
      name: country.name,
    });
    return res.result;
  }
  // #endregion

  // #region Regions
  async addRegion(region: Region): Promise<Region> {
    const res: { result: Region } = await this.httpAdapter.post(
      'api/services/app/Region/Create',
      region,
    );
    return res.result;
  }

  async updateRegion(region: Region): Promise<Region> {
    const res: { result: Region } = await this.httpAdapter.put(
      'api/services/app/Region/Update',
      region,
    );
    return res.result;
  }

  async deleteRegion(region: Region): Promise<void> {
    await this.httpAdapter.delete('api/services/app/Region/Delete', { id: region.id });
  }

  async addRiver(river: River): Promise<River> {
    const res: { result: River } = await this.httpAdapter.post(
      'api/services/app/RiverSystem/AddNew',
      river,
    );
    return res.result;
  }

  async updateRiver(river: River): Promise<River> {
    const res: { result: River } = await this.httpAdapter.put(
      'api/services/app/RiverSystem/Update',
      river,
    );
    return res.result;
  }

  async moveRiver(river: River): Promise<River> {
    const res: { result: River } = await this.httpAdapter.post(
      'api/services/app/RiverSystem/ChangeRegion',
      river,
    );
    return res.result;
  }

  async renameRiver(river: River): Promise<River> {
    const res: { result: River } = await this.httpAdapter.post(
      'api/services/app/RiverSystem/ChangeName',
      river,
    );
    return res.result;
  }

  async deleteRiver(river: River): Promise<void> {
    await this.httpAdapter.delete('api/services/app/RiverSystem/Delete', { id: river.id });
  }
}

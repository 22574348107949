import { makeAutoObservable } from 'mobx';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import ScenarioService from 'src/domain/service/ScenarioService';
import RootStore from 'src/stores/rootStore';
import { getDateFromUrl } from 'src/utils/date';
import { RunModelProgress } from 'src/domain/entity/ActiveRunProgress';
import Utils from 'src/utils/utils';
import { FiltersData } from './types';

interface Run {
  startTime: string | null;
  completedTime: string | null;
  id: number;
  runId: number | null;
  description: string;
  riverSystem: string;
  scenarioName: string;
  status: string;
  runModelJobUrlLink: string | null;
  runModelDetails?: Record<string, string>;
}

type CurrentStep = 'FETCHING' | 'DONE' | 'REFETCHING';

export default class ModelRunHistoryStore {
  _: RootStore;

  riverSystems: Array<River> = [];

  runs: Array<Run> = [];

  errorDescription = '';

  currentStep: CurrentStep = 'FETCHING';

  constructor(
    rootStore: RootStore,
    readonly scenarioService: ScenarioService,
    readonly riverSystemService: RiverSystemService,
  ) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  async fetchAllRiverSystems(): Promise<void> {
    const riverSystems = await this.riverSystemService.fetchRiverSystemsWithActiveScenarios();
    this.setRiverSystems(riverSystems);
  }

  async refetchRuns(filters: FiltersData): Promise<void> {
    this.setCurrentStep('REFETCHING');
    await this.getRuns(filters);
  }

  async fetchRuns(filters: FiltersData): Promise<void> {
    this.setCurrentStep('FETCHING');
    await this.getRuns(filters);
  }

  private async getRuns(filters: FiltersData): Promise<void> {
    try {
      const [startDate, endDate] = getDateFromUrl(filters.dateRange);
      const runs = await this.scenarioService.getByScenarioState({
        riverSystemId: filters.riverSystemId,
        modelType: filters.modelType,
        startDate,
        endDate,
        runId: filters.runId ? parseInt(filters.runId, 10) : undefined,
        states: filters.status ?? [
          'RunScenarioSuccessful',
          'RunScenarioFailed',
          'RunScenarioCancelled',
        ],
      });

      const scenarioIds = runs.filter((s) => s.state === 'RunScenarioFailed').map((s) => s.id);

      const runModelDetails = await this.scenarioService.getProgressFromActiveRuns(scenarioIds);
      const detailsMapping = scenarioIds.reduce(
        (acum, id, i) => ({ ...acum, [id]: runModelDetails[i] }),
        {} as Record<string, RunModelProgress>,
      );

      const formattedRuns = runs.map((run) => ({
        startTime: run.runStartTime,
        completedTime: run.runCompletedTime,
        runId: run.databricksScenarioRunId,
        description: run.description,
        scenarioName: run.name,
        id: run.id,
        riverSystem: run.riverSystemName,
        modelType: run.type,
        status: run.state,
        runModelJobUrlLink: run.runModelJobUrlLink,
        runModelDetails: detailsMapping[run.id]?.stepsDescription,
      }));
      this.setRuns(formattedRuns);
    } catch (err) {
      this.errorDescription = Utils.getErrorMessage(err);
    } finally {
      this.setCurrentStep('DONE');
    }
  }

  private setRuns(runs: Array<Run>) {
    this.runs = runs;
  }

  public setCurrentStep(currentStep: CurrentStep): void {
    this.currentStep = currentStep;
  }

  get isRefetching(): boolean {
    return this.currentStep === 'REFETCHING';
  }

  get isLoading(): boolean {
    return this.currentStep === 'FETCHING';
  }

  public setRiverSystems(riverSystems: Array<River>): void {
    this.riverSystems = riverSystems;
  }
}

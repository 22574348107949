import { makeAutoObservable } from 'mobx';
import { ModelType } from 'src/domain/entity/ModelType';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import ScenarioService from 'src/domain/service/ScenarioService';
import { getCurrentStepFromScenarioState } from 'src/domain/entity/ScenarioState';
import RootStore from 'src/stores/rootStore';
import { Scenario } from 'src/domain/entity/Scenario';
import { GetMergeFlowConfigurationHistoricalOutput } from 'src/domain/service/RiverSystemService/dto';
import { CurrentStep } from './createScenarioStore';

const scenarioInitData: Scenario = {
  id: -1,
  name: '',
  description: '',
  sanityCheckJobUrlLink: '',
  riverSystem: {
    id: -1,
    name: '',
    stations: [],
    region: { id: -1, name: '', country: { id: '', name: '', isoCode: '', regions: [] } },
  },
  notificationUserEmail: '',
};

export default class ScenarioDetailsStore {
  _: RootStore;

  riverScenarios: Array<Scenario> = [];

  modelType: ModelType = 'Validation';

  riverSystem: River = {
    id: -1,
    name: '',
    stations: [],
    region: { id: -1, name: '', country: { id: '', name: '', isoCode: '', regions: [] } },
  };

  country = this.riverSystem?.region?.country.id;

  region = this.riverSystem?.region?.id;

  scenario: Scenario = scenarioInitData as Scenario;

  constructor(
    rootStore: RootStore,
    readonly riverSystemService: RiverSystemService,
    readonly scenarioService: ScenarioService,
  ) {
    makeAutoObservable(this, { _: false });
    this._ = rootStore;
  }

  async createScenario(name: string, description: string): Promise<void> {
    try {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SUBMITTING_SCENARIO);
      const data = await this.scenarioService.create(
        name,
        this.modelType,
        this.riverSystem,
        description,
      );
      this.setScenario(data);
    } catch (err) {
      this._.createScenarioStore.setCurrentStep(CurrentStep.CREATE_SCENARIO);
      throw err;
    } finally {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SCENARIO_CREATED);
    }
  }

  async editScenarioDescription(id: number, description: string): Promise<void> {
    await this.scenarioService.editScenarioDescription(id, description);
    this.setScenario({ ...this.scenario, description });
  }

  async editScenarioName(id: number, newName: string): Promise<void> {
    await this.scenarioService.editScenarioName(id, newName);
    this.setScenario({ ...this.scenario, name: newName });
  }

  async duplicateScenario(
    originalId: number,
    newName: string,
    description: string,
    riverId: number,
  ): Promise<void> {
    try {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SUBMITTING_SCENARIO);
      const data = await this.scenarioService.duplicate(originalId, newName, description, riverId);
      this.setScenario(data);
    } catch (err) {
      this._.createScenarioStore.setCurrentStep(CurrentStep.CREATE_SCENARIO);
      throw err;
    } finally {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SCENARIO_CREATED);
    }
  }

  async deleteScenario(scenarioId: number): Promise<void> {
    try {
      this._.createScenarioStore.setCurrentStep(CurrentStep.SUBMITTING_SCENARIO);
      await this.scenarioService.delete(scenarioId);
    } catch (err) {
      this._.createScenarioStore.setCurrentStep(CurrentStep.CREATE_SCENARIO);
      throw err;
    }
  }

  async softDeleteScenario(scenarioId: number): Promise<void> {
    await this.scenarioService.softDelete(scenarioId);
  }

  async loadScenarioById(id: number): Promise<CurrentStep> {
    const data = await this.scenarioService.get(id);
    this.setScenario(data);
    await this._.scenarioDetailsStore.loadRiverById();
    return getCurrentStepFromScenarioState(data.state);
  }

  async loadRiverById(): Promise<void> {
    const data = await this.riverSystemService.get(this.scenario.riverSystem.id);
    this.setRiverSystem(data);
  }

  public clearScenario(): void {
    this.setScenario(scenarioInitData as Scenario);
  }

  public defineScenarioInfo(riverSystem: River): void {
    this.riverSystem = riverSystem;
    this._.createScenarioStore.setCurrentStep(CurrentStep.RIVER_SELECTED);
  }

  public removeScenarioInfo(): void {
    this.riverSystem = {
      id: -1,
      name: '',
      stations: [],
      region: { id: -1, name: '', country: { id: '', name: '', isoCode: '', regions: [] } },
    };
    this._.createScenarioStore.setCurrentStep(CurrentStep.DATA_LOADING);
  }

  async fetchScenarios(): Promise<void> {
    const riverScenarios = await this.scenarioService.getAll(this.riverSystem.id);
    this.setRiverScenarios(riverScenarios);
  }

  public getScenarioIdByName(scenarioName: string): number | undefined {
    const scenario = this.riverScenarios.find((s) => s.name === scenarioName);
    return scenario?.id;
  }

  public setRiverScenarios(riverScenarios: Array<Scenario>): void {
    this.riverScenarios = riverScenarios;
  }

  public setScenario(scenario: Scenario): void {
    this.scenario = scenario;
  }

  public setRiverSystem(river: River): void {
    this.riverSystem = river;
  }

  public defineModelType(modelType: ModelType): void {
    this.modelType = modelType;
    this._.createScenarioStore.setCurrentStep(CurrentStep.CREATE_SCENARIO);
  }

  public isLoadedScenarioEqual(scenarioId: number): boolean {
    return this.scenario.id === scenarioId;
  }

  public async getMergeFlowConfigurationHistoricalOutput(
    scenarioId: number,
  ): Promise<GetMergeFlowConfigurationHistoricalOutput> {
    return this.scenarioService.GetScenarioMergeFlowConfigurationHistoricalOutput(scenarioId);
  }

  public isScenarioLoading(): boolean {
    return this._.createScenarioStore.isLoadingInfo();
  }
}

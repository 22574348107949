import { makeAutoObservable } from 'mobx';
import { Country } from 'src/domain/entity/Country';
import { Region } from 'src/domain/entity/Region';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import ScenarioService from 'src/domain/service/ScenarioService';

export enum CurrentStep {
  DATA_LOADING,
  DATA_READY,
  RIVER_SELECTED,
  CREATE_SCENARIO,
  SUBMITTING_SCENARIO,
  SCENARIO_CREATED,
  LOADING_TEMPLATE,
  CREATE_TEMPLATE,
  SUBMITTING_TEMPLATE,
  TEMPLATE_DATABRICKS_FAIL,
  TEMPLATE_CREATED,
  TEMPLATE_RESET_OR_DUPLICATE,
  VALIDATION_MODEL_LOADING,
  VALIDATION_MODEL,
  INITIATING_CANCEL_SANITY_CHECK,
  CANCELLING_SANITY_CHECK,
  VALIDATION_MODEL_SUBMITTING,
  VALIDATION_MODEL_FETCHING_RESULTS,
  VALIDATION_MODEL_DATABRICKS_FAIL,
  VALIDATION_MODEL_FAIL,
  VALIDATION_MODEL_SUCCESS,
  RUN_MODEL,
  RUN_MODEL_FINISHED,
}

export default class CreateScenarioStore {
  currentStep = CurrentStep.DATA_LOADING;

  countriesName: Array<string> = [];

  Countries: Array<Country> = [];

  regionsName: Array<string> = [];

  riverSystems: Array<River> = [];

  constructor(
    readonly riverSystemService: RiverSystemService,
    readonly scenarioService: ScenarioService,
  ) {
    makeAutoObservable(this);
  }

  async fetchCountries(): Promise<void> {
    try {
      this.setCurrentStep(CurrentStep.DATA_LOADING);
      const countries = await this.riverSystemService.getCountriesGrouppedByRegion();
      this.setCountries(countries.map((c) => c.isoCode));
      this.Countries = countries;
    } finally {
      this.setCurrentStep(CurrentStep.DATA_READY);
    }
  }

  async fetchRegions(country: string): Promise<void> {
    const c: Country | undefined = this.Countries.find(
      (x) => x.isoCode.toUpperCase() === country.toUpperCase(),
    );
    const regions = c ? c.regions : [];
    this.setRegions(regions);
  }

  async fetchRiverSystems(country: string, region: string): Promise<void> {
    try {
      this.setCurrentStep(CurrentStep.DATA_LOADING);
      const riverSystems = await this.riverSystemService.getByCountryAndRegion(country, region);
      this.setRiverSystems(riverSystems);
    } finally {
      this.setCurrentStep(CurrentStep.DATA_READY);
    }
  }

  async getRiverSystems(riverSystemId: number | undefined): Promise<River | undefined> {
    try {
      if (!riverSystemId) return undefined;
      const riverSystems = await this.riverSystemService.get(riverSystemId);
      return riverSystems;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  public setCountries(countriesName: Array<string>): void {
    this.countriesName = countriesName;
  }

  public setRegions(regions: Array<Region>): void {
    this.regionsName = regions.map((r) => r.name);
  }

  public setRiverSystems(riverSystems: Array<River>): void {
    this.riverSystems = riverSystems;
  }

  public setCurrentStep(step: CurrentStep): void {
    this.currentStep = step;
  }

  public isLoadingInfo(): boolean {
    return this.currentStep === CurrentStep.DATA_LOADING;
  }

  public isRiverSelected(): boolean {
    return this.currentStep >= CurrentStep.RIVER_SELECTED;
  }

  public isCreatingScenario(): boolean {
    return this.currentStep === CurrentStep.SUBMITTING_SCENARIO;
  }

  public isSubmittingTemplate(): boolean {
    return this.currentStep === CurrentStep.SUBMITTING_TEMPLATE;
  }

  public isScenarioCreated(): boolean {
    return this.currentStep >= CurrentStep.CREATE_SCENARIO;
  }

  public isTemplateLoading(): boolean {
    return this.currentStep === CurrentStep.LOADING_TEMPLATE;
  }

  public isTemplateFileReady(): boolean {
    return this.currentStep === CurrentStep.TEMPLATE_CREATED;
  }

  public isValidationModelLoading(): boolean {
    return this.currentStep === CurrentStep.VALIDATION_MODEL_LOADING;
  }

  public isValidationModelProcessing(): boolean {
    return (
      this.currentStep === CurrentStep.VALIDATION_MODEL_SUBMITTING ||
      this.currentStep === CurrentStep.VALIDATION_MODEL_FETCHING_RESULTS
    );
  }

  public isValidationModelFetchingResults(): boolean {
    return this.currentStep === CurrentStep.VALIDATION_MODEL_FETCHING_RESULTS;
  }

  public isValidationModelSuccess(): boolean {
    return this.currentStep === CurrentStep.VALIDATION_MODEL_SUCCESS;
  }

  public isValidationModelFailed(): boolean {
    return (
      this.currentStep === CurrentStep.VALIDATION_MODEL_FAIL ||
      this.currentStep === CurrentStep.VALIDATION_MODEL_DATABRICKS_FAIL
    );
  }

  public isSanityCheckResultsReady(): boolean {
    return this.currentStep >= CurrentStep.VALIDATION_MODEL_FETCHING_RESULTS;
  }

  public isSanityCheckCompleted(): boolean {
    return this.currentStep === CurrentStep.VALIDATION_MODEL_SUCCESS;
  }

  public isShowCancelSanityCheck(): boolean {
    return (
      this.currentStep === CurrentStep.INITIATING_CANCEL_SANITY_CHECK ||
      this.currentStep === CurrentStep.CANCELLING_SANITY_CHECK ||
      this.currentStep === CurrentStep.VALIDATION_MODEL_SUBMITTING ||
      this.currentStep === CurrentStep.VALIDATION_MODEL_FETCHING_RESULTS
    );
  }

  public isCancellingSanityCheck(): boolean {
    return this.currentStep === CurrentStep.CANCELLING_SANITY_CHECK;
  }

  public isInitiatingCancelSanityCheck(): boolean {
    return this.currentStep === CurrentStep.INITIATING_CANCEL_SANITY_CHECK;
  }

  get hasModelAlreadyRan(): boolean {
    return this.currentStep === CurrentStep.RUN_MODEL;
  }
}

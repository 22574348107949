import { makeAutoObservable } from 'mobx';
import { Country } from 'src/domain/entity/Country';
import { Region } from 'src/domain/entity/Region';
import { River } from 'src/domain/entity/River';
import { RiverSystemService } from 'src/domain/service/RiverSystemService';
import { RiverSystemUpdateInput } from 'src/domain/service/RiverSystemService/dto';
import RootStore from 'src/stores/rootStore';

export default class RiverSystemAddEditStore {
  countriesName: Array<string> = [];

  countries: Array<Country> = [];

  allCountries: Array<Country> = [];

  regionsName: Array<string> = [];

  regions: Array<Region> = [];

  riverSystems: Array<River> = [];

  isLoading = true;

  isSubmitting = false;

  constructor(readonly _: RootStore, readonly riverSystemService: RiverSystemService) {
    makeAutoObservable(this);
  }

  async fetchCountries(): Promise<void> {
    try {
      this.isLoading = true;
      const countries = await this.riverSystemService.getCountriesGrouppedByRegion();
      this.setCountries(countries.map((c) => c.isoCode));
      this.countries = countries;
    } finally {
      this.isLoading = false;
    }
  }

  async fetchAllCountries(): Promise<void> {
    try {
      this.isLoading = true;
      const countries = await this.riverSystemService.getAllCountries();
      this.allCountries = countries;
    } finally {
      this.isLoading = false;
    }
  }

  async fetchRegions(country: string): Promise<void> {
    const c = this.countries.find((t) => t.isoCode.toUpperCase() === country.toUpperCase());
    this.setRegions(c?.regions || []);
  }

  async fetchRiverSystems(country: string, region: string): Promise<void> {
    try {
      this.isLoading = true;
      const riverSystems = await this.riverSystemService.getByCountryAndRegion(country, region);
      this.setRiverSystems(riverSystems);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchRiverSystemsByRegionId(
    regionId: number,
    validRiverSystemOnly?: boolean,
  ): Promise<void> {
    try {
      this.isLoading = true;
      const rivers = await this.riverSystemService.getByRegionId(
        regionId,
        validRiverSystemOnly ?? false,
      );
      this.setRiverSystems(rivers);
    } finally {
      this.isLoading = false;
    }
  }

  public setCountries(countriesName: Array<string>): void {
    this.countriesName = countriesName;
  }

  public setRegions(regions: Array<Region>): void {
    this.regions = regions;
    this.regionsName = regions.map((r) => r.name);
  }

  public setRiverSystems(riverSystems: Array<River>): void {
    this.riverSystems = riverSystems;
  }

  public setIsSubmitting(isSubmitting: boolean): void {
    this.isSubmitting = isSubmitting;
  }

  public async editRiverSystem(riverSystemId: number): Promise<void> {
    try {
      this.setIsSubmitting(true);
      const { generatorsStructure, stationsStructure } = this._.riverSystemStore;
      const input: RiverSystemUpdateInput = {
        id: riverSystemId,
        flows: this._.mergeSplitStore.stationFlows,
        stations: stationsStructure
          .filter((s) => !s.isDeleted)
          .map((s) => {
            return {
              id:
                s.id?.toString().includes('local__id__') || s.id === undefined
                  ? undefined
                  : parseInt(s.id, 10),
              name: s.name,
              isEnabled: s.isEnabled,
              includeStorage: s.includeStorage,
              generators: generatorsStructure[s.name]
                .filter((g) => !g.isDeleted)
                .map((g) => ({
                  id: g.id,
                  name: g.name,
                  isEnabled: g.isEnabled,
                  priority: g.priority,
                })),
            };
          }),
      };
      await this.riverSystemService.update(input);
      this._.riverSystemStore.updateInitialSnapshot();
      this._.mergeSplitStore.updateInitialSnapshot();
    } finally {
      this.setIsSubmitting(false);
    }
  }

  public async addCountry(newCountry: Country): Promise<Country> {
    return this.riverSystemService.addCountry(newCountry);
  }

  public async updateCountry(updatedCountry: Country): Promise<Country> {
    return this.riverSystemService.updateCountry(updatedCountry);
  }

  public async deleteCountry(countryToDelete: Country): Promise<void> {
    return this.riverSystemService.deleteCountry(countryToDelete);
  }

  public async addRegion(newRegion: Region): Promise<Region> {
    return this.riverSystemService.addRegion(newRegion);
  }

  public async updateRegion(region: Region): Promise<Region> {
    return this.riverSystemService.updateRegion(region);
  }

  public async deleteRegion(region: Region): Promise<avoid> {
    return this.riverSystemService.deleteRegion(region);
  }

  public async addRiver(river: River): Promise<River> {
    return this.riverSystemService.addRiver(river);
  }

  public async updateRiver(river: River): Promise<River> {
    return this.riverSystemService.updateRiver(river);
  }

  public async moveRiver(river: River): Promise<River> {
    return this.riverSystemService.moveRiver(river);
  }

  public async renameRiver(river: River): Promise<River> {
    return this.riverSystemService.renameRiver(river);
  }

  public async deleteRiver(river: River): Promise<void> {
    return this.riverSystemService.deleteRiver(river);
  }

  public async getRiver(riverId: number): Promise<River> {
    return this.riverSystemService.get(riverId);
  }
}
